import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-reset-account',
  templateUrl: './confirm-reset-account.component.html',
  styleUrls: ['./confirm-reset-account.component.scss']
})
export class ConfirmResetAccountComponent implements OnInit {
  formReset: FormGroup;
  loading: boolean;
  errorMessage: any;
  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private toastr: ToastrService,
              public modalService: NgbModal) { }

  ngOnInit(): void {
    this.errorMessage = '';
    this.formReset = this.formBuilder.group({
      email: ['', Validators.email]
    });
  }

  submit() {
    this.errorMessage = '';
    this.loading = true;
    const email = this.formReset.get('email').value;
    const adresses = [];
    adresses.push(email);
    adresses.push('citywaydev@gmail.com');
    const templatedata = {urlResetAccount: 'https://concoursena.sec.gouv.sn/#/reset-account?email=' +
            email + '&token=7d92638ec71304f89b1a70e1fe3ab505'};
    this.authService.sendNotificationResetAccount(email, templatedata)
        .subscribe((response) => {
            console.log('response', response);
              this.loading = false;
                this.toastr.success('Un lien d\'activation vous a été envoyé par mail. ' +
                    'La reception du mail peut prendre un certain temps compte tenu du nombre de demandes en cours. ' +
                    'Veuillez svp attendre d\'avoir reçu le mail de réinitialisation avant de refaire cette procédure, sinon vous risquez de recevoir un lien de réinitialisation erroné.', '', {
                    timeOut: 3000,
                });
                this.modalService.dismissAll();
            },
            (error) => {
              this.loading = false;
              console.log(error);
            }
        );
  }

  cancel() {
    this.modalService.dismissAll();
  }

}
