<div class="app-admin-wrap layout-sidebar-compact clearfix sidebar-dark-purple" 
[ngClass]="{'sidenav-open': navService.sidebarState.sidenavOpen}" >
    <div class="side-content-wrap" *ngIf="displayBloc">
        <app-sidebar-compact></app-sidebar-compact>
    </div>
    <div [ngClass]="{'main-content-wrap d-flex flex-column': displayBloc , '' : !displayBloc }">
        <app-header-sidebar-compact></app-header-sidebar-compact>
        <div class="main-content" [ngStyle]="{'margin-top': !displayBloc ? '1%' : ''}">
            <router-outlet class="force-scroll"></router-outlet>
        </div>
        <div class="flex-grow-1"></div>
        <app-footer *ngIf="displayBloc"></app-footer>
        <div class="module-loader" *ngIf="moduleLoading">
            <div class="spinner spinner-bubble spinner-bubble-primary mr-3"></div>
        </div>
    </div>
</div>
<app-search *ngIf="searchService.searchOpen && displayBloc"></app-search>