import { Injectable } from '@angular/core';
import {
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  $reload = new Subject<boolean>();
  $reloadSidebar = new Subject<boolean>();
  $reloadDelegationComponent = new Subject<boolean>();
  $reloadActivity = new Subject<boolean>();
  constructor() { }

  getStatus(status) {
    switch (status) {
      case 'En  instruction':
      case 'Seconde instruction':
      case 'Demande envoyée':
      case 'Autre Instruction':
      case 'Ouvert en traitement : Dossier Simple':
      case 'Validation du projet d\'arrêté municipal':
      case 'Réintroduction':
      case 'Retourné par le representant de l\'état':
      case 'Autre instruction':
      case 'Génération du certificat par l\'agent assermenté':
      case 'Retourné par le Bureau de depot':
      case 'Enregistré':
      case 'Numéroté':
      case 'Imputé':
      case 'Numérotation de la déclaration d\'établissement':
      case 'Rechargé':
      case 'Retourné par le DRT':
      case 'Procès-verbal retourné par le DRT':
      case 'Projet arrêté élaboré':
      case 'Demande réintroduite':
      case 'Demande à compléter':
      case 'Rapport d\'enquête':
      case 'Retourné par le DUA':
      case 'Soumis':
      case 'Transmis':
      case 'Retourner au BECC':
      case 'Recevable présélection':
      case 'Notifié entretien':
      case 'Demande inscrite en session':
      case 'En commission':
      case 'date de visite':
      case 'Accusé de réception':
      case 'reception':
      case 'Dossier payé':
        return 'badge-primary';
        break;

      case 'En attente avis DA':
      case 'En cours d\'instruction':
      case 'En instance de signature chez le maire':
      case 'En instance d\'approbation chez le representant de l\'état':
      case 'En cours d\'instuction':
      case 'Récépissé en instance de signature auprès du chef de service':
      case 'En attente avis DUA':
      case 'En attente avis DGUA':
      case 'Ouvert en traitement: Dossier simple':
      case 'Ouvert en traitement: Dossier complexe':
      case 'Ouvert en traitement':
      case 'En attente de transmission au chef de service':
      case 'En instance de signature auprès du Maire':
      case 'En attente de transmission au maire':
      case 'En instance d\'approbation auprès du representant de l\'Etat':
      case 'SD, cachet du Chef de service':
      case 'En attente de la decision du chef de service':
      case 'En instance de signature du PV':
      case 'En instance de la signature du certificat auprès du chef de service':
      case 'Attestation en attente de numérotation':
      case 'Attestation en attente de signature':
      case 'Projet de lettre en attente de transmission':
      case 'Projet d\'arrêté en rédaction':
      case 'En validation':
      case 'Rapport protection civile en élaboration':
      case 'En traitement':
      case 'PV en validation auprès du DRT':
      case 'Retourné par le Maire':
      case 'Retourné par le maire':
      case 'Réception de la recevabilité du dossier':
      case 'En attente retrait diplôme':
      case 'Vérification de l\'adresse mail':
      case 'En attente de paiement':
      case 'En attente retrait duplicata':
      case 'Traitement de la recevabilité du dossier':
      case 'En instruction':
      case 'En attente RV':
      case 'Rv en retard':
      case 'En attente du demandeur':
      case 'Ouvert en traitement: Dossier complexe : SPLITAND':
      case 'Numérotation du contrat et de la DMT':
      case 'Etude de recevabilité':
      case 'Etude de recevabilité du dossier':
      case 'arrete transmis au SGG':
      case 'Décision du DRT':
      case 'Projet arrete en validation chez le DRT':
      case 'PV en instance de validation auprès du ministre':
      case 'En instance de validation auprès du ministre':
      case 'PV en attente de transmission au MTTA':
      case 'PV transmis au ministre':
      case 'PV en rédaction':
      case 'Proces verbal en validation':
      case 'PV élaboré':
      case 'En cours d\'élaboration':
      case 'En cours de validation':
      case 'Validation':
      case 'Validation de l\'acte d\'état-civil':
      case 'En cours de traitement':
      case 'Elaboration':
      case 'Traitement en cours':
      case 'Validation en cours':
      case 'Pv en instance de signature auprès du chef de service':
      case 'En attente de signature de la lettre de rejet':
      case 'En attente de selection':
      case 'En attente de traitement':
      case 'En cours de signature':
      case 'Elaboration du Procès verbal':
      case 'En attente de la carte':
      case 'Décision du Président':
      case 'Proposition de reprogrammation':
        return 'badge-warning';
        break;

      case 'Impression du certificat':
      case 'Disponible':
      case 'Eligible':
      case 'Traitement complet (typer)':
      case 'Traitement complet':
      case ' Validé par le DGUA':
      case 'Validé par le DGUA':
      case 'Validé par le DUA':
      case 'Numérotation de l\'arrêté':
      case 'Générer note de présentation et projet d\'arrêté':
      case 'Bon pour signature':
      case 'Corrigé':
      case 'Scan du contrat ':
      case 'Rendez-vous à attribuer':
      case 'Demande signée par le ministre, en instruction chez le DA':
      case 'Demande signée par le ministre, en instruction chez le DGUA':
      case ' Signer le récépissé ':
      case 'Projet de lettre générée':
      case 'Lettres PC et VT générés':
      case 'Rapport transmis au DRT':
      case 'ouvert en traitement':
      case 'Impression de la carte de guide':
      case 'disponible':
      case 'Passeport disponible':
      case 'Passeport en impression':
      case 'Passeport retiré':
      case 'Signé':
      case 'Arrêté signé par le Ministre':
      case 'En présélection':
      case 'Présélectionné':
      case 'Diplôme établi':
      case 'Élaboration du bulletin':
      case 'Elaboration de l\'acte':
      case 'Validé':
      case 'VALIDE':
      case 'Numérotation du contrat':
      case 'corrigé par l\'entreprise':
      case 'Candidats admission':
        return 'badge-success';
        break;

      case 'Rejeté':
      case 'Demande à signer par le ministre,rejeté par DGUA':
      case 'Rejété par le DGUA':
      case 'Rejeté définitivement':
      case 'Rejeté par le Bureau de depot':
      case 'Rejeté par l\'agent bureau de dépot':
      case 'lettre de rejet en signature auprès du chef de service':
      case 'Rejeté par l\'agent du bureau de dépot':
      case 'Rejeté par l\'inspection':
      case 'PV rejeté par le ministre':
      case 'Rejeté par l\'inspection du travail':
      case 'Rejété par le DUA':
      case 'Traitement suspendu':
      case 'Non retenu':
      case 'Non préselectionné':
      case 'Elaboration de la lettre de rejet':
      case 'Dossier rejeté définitivement':
      case 'Dossier physique rejeté':
        return 'badge-danger';
        break;

      case 'Suspendu':
      case 'suspendu':
        return 'badge-light';
        break;
      case 'Brouillon':
        return 'badge-draft';
        break;
      case 'Recevable':
      case 'Décision du Chef de service':
      case 'SD, Décision du Chef de service':
      case 'Projet d\'arrêté en traitement à la DOALC':
      case 'Décision du chef de service':
        return 'badge-receivable';
        break;
      case 'Arrêté d\'autorisation en instance de signature du Maire':
        return 'badge-signstate';
        break;
      case 'Traitement Complet':
        return 'badge-complet';
        break;

      case 'Dossier Réintroduit':
      case 'Réintroduit':
        return 'badge-reintroduit';
        break;
      default: return 'badge-light';
    }
  }

  getPermission(user: any, entreprise: any) {
    const permission = {permission: true, message: ''};
    if (user.user.type === 'entreprise') {
      if (entreprise !== undefined && entreprise !== null && entreprise.verified) {
        permission.permission = true;
        permission.message = '';
      } else {
        permission.permission = false;
        permission.message = 'La demande de vérification de votre entreprise n\'est pas encore acceptée.' +
            ' Veuillez déposer une demande de vérification si ce n\'est déjà fait et attendre la validation de celle-ci';
      }
    } else if (user.user.type === 'representant_entreprise') {
      if (entreprise !== undefined && entreprise !== null && entreprise.verified) {
        permission.permission = true;
        permission.message = '';
        if (user.verified) {
          permission.permission = true;
          permission.message = '';
        } else {
          permission.permission = false;
          permission.message = 'Pour faire un dépôt votre compte doit être activé par le compte responsable de votre entreprise. Veuillez demander au responsable du compte de votre entreprise d\'activer votre compte';
        }
      } else {
        permission.permission = false;
        permission.message = 'La demande de vérification de votre entreprise n\'est pas encore acceptée.' +
            ' Veuillez déposer une demande de vérification depuis le compte de votre entreprise si ce n\'est déjà fait et attendre la validation de celui-ci';
      }
    }
    return permission;
  }

  hasPermissionFile(permissions, codePiece, action, activity) {
    const permission = permissions.filter((result) => result.action === action);
    const codePermission = permission.filter(
      (result) => result.codePermission === codePiece
    );

    if (!activity) {
      return permission.length >= 1 && codePermission.length >= 1;
    } else {
      const permissionActivity = codePermission.filter(
        (result) => result.codeActivite === activity
      );
      return (
        permission.length >= 1 &&
        codePermission.length >= 1 &&
        permissionActivity.length >= 1
      );
    }
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  patternDateValidator(control: AbstractControl) {
    const dateNaiss: any = control.get('dateNaiss').value;
    console.log('dateNaiss', dateNaiss);
    if (dateNaiss !== null && dateNaiss !== undefined  && dateNaiss !== '') {
      // const myDate = this.getDateFormat(dateNaiss);
      let date = dateNaiss;
      if (dateNaiss.day !== undefined) {

        let day = dateNaiss.day;
        let month = dateNaiss.month;
        if (month.toString().length === 1) {
          month = '0' + month;
        }
        if (day.toString().length === 1) {
          day = '0' + day;
        }
        date =  day + '-' + month + '-' + dateNaiss.year;
      }
      // const regex = /[0-9]{2}-[0-9]{2}-[0-9]{4}/i;
      const regex = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-[0-9]{4}$/;
      const valid = regex.test(date);
      if (!valid) {
        control.get('dateNaiss').setErrors({ NoPassswordMatch: true });
      } else {
        control.get('dateNaiss').setErrors(null);
      }
    }
  }

  passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('newPassword').value;
    const confirmPassword: string = control.get('newPasswordConfirm').value;
    if (password !== confirmPassword) {
      control.get('newPasswordConfirm').setErrors({ NoPassswordMatch: true });
    }
  }
  passwordMatchValidatorEntreprise(control: AbstractControl) {
    const password: string = control.get('newPasswordEntreprise').value;
    const confirmPassword: string = control.get('newPasswordConfirmEntreprise').value;
    if (password !== confirmPassword) {
      control.get('newPasswordConfirmEntreprise').setErrors({ NoPassswordMatch: true });
    }
  }
  passwordLengthValidator(control: AbstractControl) {
    const password: string = control.get('newPassword').value;
    if (password !== null && password !== undefined && password.length <= 7) {
      control.get('newPassword').setErrors({ NoPassswordLength: true });
    }
  }
  passwordLengthValidatorEntreprise(control: AbstractControl) {
    const password: string = control.get('newPasswordEntreprise').value;
    if (password !== null && password !== undefined && password.length <= 7) {
      control.get('newPasswordEntreprise').setErrors({ NoPassswordLength: true });
    }
  }

  getDateFormat(item) {
    if (item === undefined || item === null || item.day === undefined) {
      return '';
    }
    let day = item.day;
    let month = item.month;
    if (month.toString().length === 1) {
      month = '0' + month;
    }
    if (day.toString().length === 1) {
      day = '0' + day;
    }
    return item.year + '-' + month + '-' + day;
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  handleDate(value) {
    let day = value.day;
    let month = value.month;
    let date;
    if (month.toString().length === 1) {
      month = '0' + month;
    }
    if (day.toString().length === 1) {
      day = '0' + day;
    }
    date = value.year + '-' + month + '-' + day;
    return date;
  }
}
