import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  displayBloc = true;

  constructor(
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.checkDisplayBloc();
  }

  checkDisplayBloc(){
    this.activateRoute.queryParams.subscribe((params) => {
      if (params && params.displayBloc == 'false') {
          this.displayBloc = false;
      }
    });
  }

}
