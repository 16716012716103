import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { LocalStoreService } from '../local-store.service';
@Injectable({
  providedIn: 'root',
})
export class ReferentielPieceService {
  constructor(
    private httpClient: HttpClient,
    private localStoreService: LocalStoreService
  ) { }

  getPiecesBycodeProcedure(code) {
    return this.httpClient.get(
      environment.api.referentiel_piece + '/piece/' + code
    );
  }

  uploadFile(codePiece, file) {
    let token = this.localStoreService.getItem('citizen_access_token');
    var formdata = new FormData();
    formdata.append('codePiece', codePiece);
    formdata.append('selectedFile', file, file.name);
    formdata.append('originalFileName', file.name);
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    };
    return fetch(
      environment.api.referentiel_piece + '/fichier/',
      requestOptions
    );
  }

  enregistrerFichier(codePiece, file) {
    let token = this.localStoreService.getItem('citizen_access_token');
    var formdata = new FormData();
    formdata.append('codePiece', codePiece);
    formdata.append('selectedFile', file, file.name);
    formdata.append('originalFileName', file.name);
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    return this.httpClient.post(
      environment.api.referentiel_piece + '/fichier/',
      formdata,
      { headers: headers }
    );
  }

  downloadPiece(code) {
    return this.httpClient.get(
      environment.api.referentiel_piece + '/fichier/' + code + '/download', { responseType: 'blob' }
    );
  }

  downloadPieceCoffre(code) {
    return this.httpClient.get(
      environment.api.referentiel_piece + '/fichierCoffre/' + code + '/download', { responseType: 'blob' }
    );
  }

  getInfosFichierByCodeFile(codeFile){
    return this.httpClient.get(
      environment.api.referentiel_piece + '/fichier/' + codeFile );
  }

  editFile(codeFile) {
    return environment.api.referentiel_piece + '/fichier/doc/editer/?code=' + codeFile;
  }

  viewFile(codeFile) {
    return environment.api.referentiel_piece + '/fichier/doc/view/?code=' + codeFile;
  }

  viewFileCoffre(codeFile) {
    return environment.api.referentiel_piece + '/fichierCoffre/doc/view/?code=' + codeFile;
  }

  updateFile(codePiece, file, codeFile) {
    let token = this.localStoreService.getItem('citizen_access_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('codePiece', codePiece);
    formdata.append('selectedFile', file);
    formdata.append('originalFileName', file.name);

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formdata,
    };
    return fetch(
      environment.api.referentiel_piece + '/fichier/' + codeFile,
      requestOptions
    );
  }

  convertFileDosToPdf(codeFichier) {
    return this.httpClient.get(
      environment.api.referentiel_piece + '/convertfichier/' + codeFichier
    );
  }

  getPieceByCitoyen(idCitoyen) {
    return this.httpClient.get(
      environment.api.referentiel_piece + '/fichierCoffre/user/' + idCitoyen
    );
  }

  uploadToCoffre(codePiece, file, userId) {
    let token = this.localStoreService.getItem('citizen_access_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('codePiece', codePiece);
    formdata.append('selectedFile', file);
    formdata.append('originalFileName', file.name);
    formdata.append('id', userId);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    };
    return fetch(
      environment.api.referentiel_piece + '/fichierCoffre/',
      requestOptions
    );
  }

  deletePieceByCitoyen(code) {
    return this.httpClient.delete(
      environment.api.referentiel_piece + '/fichierCoffre/supprimer/' + code
    );
  }
}
