import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutsModule } from './layouts/layouts.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FormVerificationComponent} from './form/form-verification/form-verification.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';

const components = [
  BtnLoadingComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    PerfectScrollbarModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
      PdfViewerModule,
  ],
    declarations: [
        components,
        FormVerificationComponent
    ],
    exports: [
        components,
        FormVerificationComponent
    ]
})
export class SharedComponentsModule { }
