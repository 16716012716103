import { environment } from "../../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LocalStoreService } from '../local-store.service';

@Injectable({
  providedIn: "root",
})
export class ReferentielRendezVousService {
  token: any;
  constructor(private httpClient: HttpClient,
    private localStoreService: LocalStoreService) {
    this.token = this.localStoreService.getItem("citizen_access_token");

  }

  refreshToken(){
    this.token = this.localStoreService.getItem("citizen_access_token");
  }

  findCitoyenRDVByIdDos(idDos) {
    return this.httpClient.get(environment.api.referentiel_rendezvous + '/findCitoyenRDVByIdDos/' + idDos)
  }

  getRendezVousByUser(iduser, codeProc, debut, fin) {
    let params = new HttpParams()
      .set("start", debut)
      .set("end", fin)
      .set("codeProcedure", codeProc);
    return this.httpClient.get(
      environment.api.referentiel_rendezvous + "/rendezVous/" + iduser + '?' + params
    );
  }

  findLocaliteByCode(codeLoc) {
    return this.httpClient.get(
      environment.api.referentiel_rendezvous + "/findLocaliteByCode/" + codeLoc
    );
  }

  getQuota(codeProc, codeLoc, monthStart, monthEnd) {
    let params = new HttpParams()
      .set("codeProcedure", codeProc)
      .set("codeLocalite", codeLoc)
    return this.httpClient.get(
      environment.api.referentiel_rendezvous + "/getQuota?" + params
    );
  }

  getMonthDay(codeProc, codeLoc, monthStart, monthEnd) {
    let params = new HttpParams()
      .set("start", monthStart)
      .set("end", monthEnd)
      .set("codeProcedure", codeProc)
      .set("codeLocalite", codeLoc)
    return this.httpClient.get(
      environment.api.referentiel_rendezvous + "/getMonthDay?" + params
    );
  }

  getPlageHorairesByQuota(idQuota) {
    return this.httpClient.get(
      environment.api.referentiel_rendezvous + "/getPlageHorairesByQuota/" + idQuota
    );
  }

  getFeries() {
    return this.httpClient.get(
      environment.api.referentiel_rendezvous + "/feries"
    );
  }

  annulerRDV(rdvDosId, codeProc, codeLoc) {
    this.refreshToken();
    let urlencodedUpdate = new URLSearchParams();
    let headerUpdate = new Headers();
    headerUpdate.append("Authorization", "Bearer " + this.token);
    headerUpdate.append("Content-Type", "application/json");
    urlencodedUpdate.append('codeProcedure', codeProc);
    urlencodedUpdate.append('codeLocalite', codeLoc);
    let params = new HttpParams()
    .set("codeProcedure", codeProc)
    .set("codeLocalite", codeLoc)
    var requestOptions = {
      method: "PUT",
      headers: headerUpdate,
      body: urlencodedUpdate,
    };
    return fetch(
      environment.api.referentiel_rendezvous +
      "/annulerRendezVous/" +
      rdvDosId + '?' + params,
      requestOptions)
  }

  createRendezVous(data) {    
    this.refreshToken();
    let headerJson = new Headers();
    headerJson.append("Content-Type", "application/json");
    headerJson.append("Authorization", "Bearer " + this.token);
    let body = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: headerJson,
      body: body,
    };
    return fetch(
      environment.api.referentiel_rendezvous + "/createRendezVous",
      requestOptions
    );
  }

  confirmationByMail(rendezVousDTO, templateId) {
    this.refreshToken();
    let body = JSON.stringify(rendezVousDTO);

    let headerUpdate = new Headers();
    headerUpdate.append("Authorization", "Bearer " + this.token);
    headerUpdate.append("Content-Type", "application/json");
    var requestOptions = {
      method: "PUT",
      headers: headerUpdate,
      body: body,
    };
    return fetch(
      environment.api.referentiel_rendezvous +
      "/confirmationByMail/" + templateId,
      requestOptions)
  }

  countCitoyenRDV(userId) {
    return this.httpClient.get(
      environment.api.referentiel_rendezvous + "/countCitoyenRDV/" + userId
    );
  }
}
