import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStoreService } from './local-store.service';
import { AuthService } from './auth.service';
import {UtilsService} from './utils.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name?: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
    permission?: boolean;

}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    agentInfos: any;
    constructor(private localStoreService: LocalStoreService, private utilsService: UtilsService) {

    }
    defaultMenu: IMenuItem[] = [
        {
            name: 'Dashboard',
            description: this.localStoreService.getItem('userInfos').user.userName,
            type: 'dropDown',
            icon: 'i-Home1',
            sub: [
                { icon: 'i-Home1', name: 'Accueil', state: '/accueil', type: 'link', permission: true},
                { icon: 'i-Folders', name: 'Concours', state: '/procedures', type: 'link', permission: true},
                { icon: 'i-Dashboard', name: 'Tableau de bord', state: '/dashboard', type: 'link', permission: true},
                // { icon: 'i-Calendar-4', name: 'Mes rendez-vous', state: '/rendezvous', type: 'link', permission: true},
            ]
        },
        /*{
            name: 'Applications',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                // { icon: 'i-Bell', name: 'E-pétition', state: 'https://senegalservices.sn/', type: 'href', permission: true },
                { icon: 'i-Split-Horizontal-2-Window', name: 'Service publique', state: 'https://senegalservices.sn/',
                    type: 'href', permission: true},
                { icon: 'i-Medal-2', name: 'Infos télédac', state: 'http://infos.teledac.gouv.sn/', type: 'href', permission: true},
            ]
        }*/
    ];
    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();
}
