import { environment } from "../../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LocalStoreService } from "../local-store.service";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ReferentielDossierService {
  token: any;
  formData = new FormData();

  constructor(
    private httpClient: HttpClient,
    private localStoreService: LocalStoreService
  ) {
    this.token = this.localStoreService.getItem("citizen_access_token");
  }

  refreshToken(){
    this.token = this.localStoreService.getItem("citizen_access_token");
  }

  getProcedureDescriptionBycodeSecteur(codeSecteur) {
    return this.httpClient.get(environment.api.referentiel_dossier + '/procedureDescription/' + codeSecteur);
  }

  getListDossierRequerant(id) {
    return this.httpClient.get(environment.api.referentiel_dossier + '/dossierRequerant/' + id);
  }
  getListDossierEntreprise(id, idEntreprise) {
    return this.httpClient.get(environment.api.referentiel_dossier + '/dossierEntreprise/' + id + '/' + idEntreprise);
  }
  getListDossierAffilie(id, idAffilie) {
    return this.httpClient.get(environment.api.referentiel_dossier + '/dossierEntrepriseAndAffilies/' + id + '/' + idAffilie);
  }

  getDossierById(id) {
    return this.httpClient.get(
      environment.api.referentiel_dossier + "/dossier/" + id
    );
  }

  getHistoryByDossier(dossierId, type) {
    let params = new HttpParams().set("dossierId", dossierId).set("type", type);
    return this.httpClient.get(
      environment.api.referentiel_dossier + "/historiquedossier?" + params
    );
  }

  getDossierByCredentials(numdoss, password) {
    return this.httpClient.get(environment.api.referentiel_dossier + '/old/findDossierByCredentials/' + numdoss + '/' + password);
  }


  rapatrier(userId, uuid) {
    return this.httpClient.get(environment.api.referentiel_dossier + '/old/rapatrier/' + userId + '/' + uuid);
  }

  createAndStartProcessInstance(dossier) {
    this.refreshToken();
    let headerJson = new Headers();
    headerJson.append("Content-Type", "application/json");
    headerJson.append("Authorization", "Bearer " + this.token);
    let data = JSON.stringify(dossier);
    var requestOptions = {
      method: "POST",
      headers: headerJson,
      body: data,
    };
    return fetch(
      environment.api.referentiel_dossier + "/dossier/startProcess",
      requestOptions
    );
  }

  createAndStartProcessInstanceFromClasseur(classeur: any) : Observable<any>{
    return this.httpClient.post(environment.api.referentiel_dossier+"/dossier/startProcessFromClasseur", classeur);
  }

  updateDossierField(dossierId, field, value) {
    this.refreshToken();
    let headerUpdate = new Headers();
    let urlencodedUpdate = new URLSearchParams();
    headerUpdate.append("Authorization", "Bearer " + this.token);
    headerUpdate.append("Content-Type", "application/x-www-form-urlencoded");
    if (field === 'dosData') {
      value = JSON.stringify(value);
    }
    urlencodedUpdate.append('value', value);
    var requestOptions = {
      method: "PUT",
      headers: headerUpdate,
      body: urlencodedUpdate,
    };
    return fetch(
      environment.api.referentiel_dossier +
      "/dossier/" +
      dossierId +
      "/field/" +
      field,
      requestOptions
    );
  }

  notifier(dossierId, templateId) {
    this.refreshToken();
    let headerJson = new Headers();
    headerJson.append("Content-Type", "application/x-www-form-urlencoded");
    headerJson.append("Accept", "application/json");
    headerJson.append("Authorization", "Bearer " + this.token);
    var requestOptions = {
      method: "PUT",
      headers: headerJson,
    };
    return fetch(
      environment.api.referentiel_dossier + "/dossier/" + dossierId + '/' + templateId + '/soumission',
      requestOptions
    );
  }

  notifierUser(dossierId, templateId) : Observable<any> {
    return this.httpClient.put(environment.api.referentiel_dossier+"/dossier/" + dossierId + '/' + templateId + '/soumission', null);
  }

  historiserDossier(id, historique) {
    this.refreshToken();
    let headerJson = new Headers();
    headerJson.append("Content-Type", "application/json");
    headerJson.append("Authorization", "Bearer " + this.token);
    let data = JSON.stringify(historique);
    var requestOptions = {
      method: "POST",
      headers: headerJson,
      body: data,
    };
    return fetch(
      environment.api.referentiel_dossier + "/historiquedossier/" + id,
      requestOptions
    );
  }

  historiser(id, historique) {
    return this.httpClient.post(environment.api.referentiel_dossier + "/historiquedossier/" + id, historique);
  }

  getSUTUrl(dossierId): any {
    return this.httpClient.get(
      environment.api.referentiel_dossier + "/dossier/"+dossierId+"/sutpayurl"
    );
  }

  getHistoriqueByDossier(dossierId) {
    let params = new HttpParams().set("dossierId", dossierId);
    return this.httpClient.get(
      environment.api.referentiel_dossier + "/historiquedossier?" + params
    );
  }

  historiserDossierAndUpdateFields(id, historique) {
    this.refreshToken();
    let headerJson = new Headers();
    headerJson.append("Content-Type", "application/json");
    headerJson.append("Authorization", "Bearer " + this.token);
    let data = JSON.stringify(historique);
    var requestOptions = {
      method: "POST",
      headers: headerJson,
      body: data,
    };
    return fetch(
      environment.api.referentiel_dossier + "/historiquedossierandupdatefileds/" + id,
      requestOptions
    );
  }

  getStatsByEntreprise(datedebut, datefin, idAccounts, codeProcedure, codeEtat){

    let params = new HttpParams()
      .set("dateDebut", datedebut)
      .set("dateFin", datefin)
      .set("codeProcedure", codeProcedure)
      .set("codeEtat", codeEtat)
      .set("idAccounts", idAccounts);
    return this.httpClient.get(
      environment.api.referentiel_dossier +
      "/stats-entreprise/?"+
      params
    );
  }

}
