import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './shared/services/ngbDateCustomParserFormatter';
import localeFr from '@angular/common/locales/fr';
import {CommonModule, registerLocaleData} from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { MigrationCompteComponent } from './views/migration-compte/migration-compte.component';
import { ResetAccountComponent } from './reset-account/reset-account.component';
import { ConfirmResetAccountComponent } from './confirm-reset-account/confirm-reset-account.component';
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    SetNewPasswordComponent,
    ResetAccountComponent,
    ConfirmResetAccountComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter
    },
    {
      provide: LOCALE_ID, useValue: 'fr-FR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
