<div class="col-md-12 form-group  form-horizontal">
    <label class="control-label">{{template.label}}<span *ngIf="template.required" class="text-danger">&nbsp;*</span></label>
    <hr style="margin-top: 5px; margin-bottom: 0px;">
    <div class="form-group row mt">
        <div class="col-md-4" *ngFor="let option of template.options">
            <div class="form-check">
                <label>
                    <input type="checkbox" disabled="{{!template.editable || isReadOnly}}"
                           [checked]="true"
                           required="{{template.required}}">
                    {{option.label}}
                </label>
            </div>
        </div>
    </div>
</div>
