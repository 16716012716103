import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { HomeLayoutComponent } from './shared/components/layouts/home-layout/home-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import {MigrationCompteModule} from './views/migration-compte/migration-compte.module';
import {ResetAccountComponent} from './reset-account/reset-account.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'procedures',
      loadChildren: () => import('./views/procedures/procedures.module').then(m => m.ProceduresdModule)
    },
    {
      path: 'init',
      loadChildren: () => import('./views/init-demande/init-demande.module').then(m => m.InitDemandeModule)
    },
    {
      path: 'initmultiple',
      loadChildren: () => import('./views/init-demande-multiple/init-demande-multiple.module').then(m => m.InitDemandeMultipleModule)
    },
    {
      path: 'rapatriement',
      loadChildren: () => import('./views/rapatriement/rapatriement.module').then(m => m.RapatriementModule)
    },
    {
      path: 'rendezvous',
      loadChildren: () => import('./views/rendez-vous/rendez-vous.module').then(m => m.RendezVousModule)
    },
    {
      path: 'account',
      loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
    },
    {
        path: 'comptes',
        loadChildren: () => import('./views/comptes/comptes.module').then(m => m.ComptesModule)
    },
    {
        path: 'migration',
        loadChildren: () => import('./views/migration-compte/migration-compte.module')
            .then(m => m.MigrationCompteModule)
    },
    {
      path: 'stats',
      loadChildren: () => import('./views/statistiques/statistiques.module')
        .then(m => m.StatistiquesModule)
    }
  ];

const routes: Routes = [
 {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  {
    path: 'reset-password',
    component: SetNewPasswordComponent
  },
  {
    path: 'reset-account',
    component: ResetAccountComponent
  },
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {
        path: 'accueil',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      },
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: 'access-form',
    loadChildren: () => import('./views/senegal-service-access-form/senegal-service-access-form.module')
        .then(m => m.SenegalServiceAccessFormModule)
  },
  {
    path: 'access-dossier',
    loadChildren: () => import('./views/senegal-service-access-dossier/senegal-service-access-dossier.module')
        .then(m => m.SenegalServiceAccessDossierModule)
  },
  {
    path: 'confirm-account',
    loadChildren: () => import('./views/confirm-account-module/confirm-account.module').then(m => m.ConfirmAccountModule)
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarCompactComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
