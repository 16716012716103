<nav id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom headroom--top headroom--pinned">
  <div class="container">
    <a class="navbar-brand mr-lg-5" [routerLink]="['/accueil']">
      <img src="./assets/images/ena.jpg">
    </a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbar_global" [ngbCollapse]="isCollapsed">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/accueil']">
              <img src="./assets/images/ena.jpg">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed"
                  [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <!--<li class="nav-item dropdown">
          <a class="nav-link no-caret" href="https://senegalservices.sn/service-en-ligne" target="_blank" data-toggle="dropdown" role="button">
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner&#45;&#45;text">Téléprocédures</span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link no-caret" target="_blank" href="https://senegalservices.sn/actualites" data-toggle="dropdown" role="button">
            <i class="ni ni-collection d-lg-none"></i>
            <span class="nav-link-inner&#45;&#45;text">Actualités</span>
          </a>
        </li>-->
        <li class="nav-item dropdown">
          <a class="nav-link no-caret" routerLink="/accueil/faq" data-toggle="dropdown" role="button">
            <i class="ni ni-collection d-lg-none"></i>
            <span class="nav-link-inner--text" >Aide</span>
          </a>
        </li>
        <div class="nav-item dropdown">
          <!--<a class="nav-link no-caret dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Lien utiles
          </a>-->
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <!-- <a class="dropdown-item" target="_blank" href="https://ess.sec.gouv.sn/e-citoyen/spip.php?rubrique8">E-Pétition</a> -->
            <a class="dropdown-item" target="_blank" href="http://www.servicepublic.gouv.sn/">Service public</a>
            <a class="dropdown-item" target="_blank" href="http://infos.teledac.gouv.sn/">Infos Teledac</a>
          </div>
        </div>
      </ul>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <li class="nav-item" *ngIf="!isAuth">
          <a routerLink="/sessions/connexion" class="">
            <button class="btn btn-outline-primary mt-3 mb-3 btn-sm">Se connecter</button>
          </a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a class="">
            <button [routerLink]="['/sessions/inscription']" class="btn btn-outline-secondary mt-3 mb-3 btn-sm">Créer un compte</button>
          </a>
        </li>
        <li class="nav-item" *ngIf="isAuth">
          <button routerLink="/procedures" class="btn btn-outline-primary mt-3 mb-3 btn-sm">Concours</button>
        </li>
        <li class="nav-item" *ngIf="isAuth">
          <button routerLink="/dashboard" class="btn btn-outline-primary mt-3 mb-3 btn-sm">Tableau de bord</button>
        </li>
        <li ngbDropdown [placement]="'bottom-right'" class="user col nav-item" *ngIf="isAuth && user !== null && user !== undefined">
          <img  [src]="avatar" id="userDropdown" ngbDropdownToggle alt="" *ngIf="avatar">
          <img src="./assets/images/faces/1.jpg" id="userDropdown" ngbDropdownToggle alt="" *ngIf="avatar == null">
          <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
              <div class="dropdown-header dropdown-item">
                  <i class="i-Lock-User mr-1"></i> {{user.user.firstName + ' ' + user.user.lastName}}
                  <h6 class="text-black mt-1 ml-4">Candidat</h6>
                <hr class="m-0">
              </div>
              <button class="dropdown-item"  routerLink="/account/profil">Mon profil</button>
              <button class="dropdown-item" (click)="goToUpdatePassword()">Modifier mot de passe</button>
              <button class="dropdown-item">Aide</button>
              <button class="dropdown-item cursor" (click)="signout()">Se déconnecter</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
