import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { LocalStoreService } from '../../services/local-store.service';
import { AuthService } from '../../services/auth.service';
import { PasswordComponent } from '../password/password.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    isAuth: boolean;
    user: any;
    avatar: any = null;

    constructor(public location: Location, private router: Router,
        public localStoreService: LocalStoreService,
        private auth: AuthService,
        private modalService: NgbModal) {
    }

    ngOnInit() {
        if (this.localStoreService.getItem('citizen_access_token')) {
            // console.log('TOKEN', this.localStoreService.getItem('citizen_access_token'));
            this.isAuth = true;
        }
        if (this.localStoreService.getItem('userInfos') === null) {
            this.isAuth = false;
        }
        const userInfos = this.localStoreService.getItem('userInfos');
        // console.log('USER', this.localStoreService.getItem('userInfos'));
      if (userInfos && userInfos.avatar) {
        this.avatar = userInfos.avatar;
      }
      this.user = userInfos;
        this.router.events.subscribe((event) => {
            this.isCollapsed = true;
            if (event instanceof NavigationStart) {
                if (event.url !== this.lastPoppedUrl) {
                    this.yScrollStack.push(window.scrollY);
                }
            } else if (event instanceof NavigationEnd) {
                if (event.url === this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
    }

    isHome() {
        const titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee === '#/home') {
            return true;
        } else {
            return false;
        }
    }
    isDocumentation() {
        const titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '#/documentation') {
            return true;
        } else {
            return false;
        }
    }

    signout() {
        this.auth.signout();

        window.location.reload();
    }

    goToUpdatePassword() {
        const options = {
            size: 'md',
            windowClass: 'password-modal',
        };
        this.modalService.open(PasswordComponent, options);
    }
}
