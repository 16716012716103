import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LocalStoreService } from '../local-store.service';
@Injectable({
  providedIn: 'root',
})
export class ReferentielTierService {
  token: any;
  constructor(private httpClient: HttpClient,
    private localStoreService: LocalStoreService) {
      this.initToken();
  }

  initToken() {
    this.token = this.localStoreService.getItem('citizen_access_token');
  }

  getAgentByAccountId(userId) {
      return this.httpClient.get(environment.api.referentiel_tiers + '/agents?idAccount=' + userId);
  }

  updateCitiZen(id, citizen) {
    this.initToken();
    const headerJson = new Headers();
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('Authorization', 'Bearer ' + this.token);
    const requestOptions = {
      method: 'PUT',
      headers: headerJson,
      body: JSON.stringify(citizen),
    };
    return fetch(
      environment.api.referentiel_tiers + '/tiers/' + id,
      requestOptions
    );
  }

  // A voir
  getAgentsImputables(idsList): Observable<any> {
    const query = 'ids=[' + idsList + ']';
    return this.httpClient.get(environment.api.referentiel_tiers + '/agents?' + query);
  }
  getEntrepriseLinked(accountId) {
    return this.httpClient.get(environment.api.referentiel_tiers + '/entreprise/linked/' + accountId);
  }

  getEntrepriseByAccountId(accountId) {
    return this.httpClient.get(environment.api.referentiel_tiers + '/entreprise?responsable.idAccount=' + accountId);
  }
  getEntrepriseByAffiliateAccountId(accountId) {
    return this.httpClient.get(environment.api.referentiel_tiers + '/entreprise/affiliate/' + accountId);
  }

  getEcitizenByAccountId(accountId) {
    return this.httpClient.get(environment.api.referentiel_tiers + '/tiers/user/' + accountId);
  }

  getEtudiantByAccountId(accountId) {
    return this.httpClient.get(environment.api.referentiel_tiers + '/etudiant-detail/' + accountId);
  }
  getAllEntreprise() {
    return this.httpClient.get(environment.api.referentiel_tiers + '/entreprise');
  }

  createEcitizen(data) {
    const user = data;
    return this.httpClient.post(
      environment.api.referentiel_tiers + '/tiers', user
    );
  }
  createEntreprise(entreprise, user) {
    const adminToken = environment.token;
    const headerJson = new Headers();
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('WWW-Authenticate', 'Basic');
    headerJson.append('Authorization', 'Bearer ' + adminToken);
    const requestOptions = {
      method: 'POST',
      headers: headerJson,
      body: JSON.stringify(entreprise)
    };
    return fetch(
        environment.api.referentiel_tiers + '/entreprise/' + user.idAccount,
        requestOptions
    );
  }
  updateEntreprise(entreprise, responsable) {
    const adminToken = environment.token;
    const headerJson = new Headers();
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('WWW-Authenticate', 'Basic');
    headerJson.append('Authorization', 'Bearer ' + adminToken);
    const requestOptions = {
      method: 'PUT',
      headers: headerJson,
      body: JSON.stringify(entreprise)
    };
    return fetch(
        environment.api.referentiel_tiers + '/entreprise/' + responsable.idAccount,
        requestOptions
    );
  }

  updateAvatar(idAccount, imgBase64): any {
    this.initToken();
    const headerJson = new Headers();
    const avatarDTO = {
      avatar: imgBase64
    };
    const data = JSON.stringify(avatarDTO);
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('Authorization', 'Bearer ' + this.token);
    const requestOptions = {
      method: 'PUT',
      headers: headerJson,
      body: data,
    };
    return fetch(
      environment.api.referentiel_tiers + '/avatar/tiers/' + idAccount,
      requestOptions
    );
  }
  getEntreprises(): any {
    const adminToken = environment.token;
    const headerJson = new Headers();
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('Authorization', 'Bearer ' + adminToken);
    const requestOptions = {
      method: 'GET',
      headers: headerJson,
    };
    return fetch(
      environment.api.referentiel_tiers + '/entreprise/',
      requestOptions
    );
  }

  createDemande(id, demande) {
    const adminToken = environment.token;
    const headerJson = new Headers();
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('WWW-Authenticate', 'Basic');
    headerJson.append('Authorization', 'Bearer ' + adminToken);
    const requestOptions = {
      method: 'POST',
      headers: headerJson,
      body: JSON.stringify(demande)
    };
    return fetch(
        environment.api.referentiel_tiers + '/demandes/' + id,
        requestOptions
    );
  }
  accepterDemande(id, demande) {
    const adminToken = environment.token;
    const headerJson = new Headers();
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('WWW-Authenticate', 'Basic');
    headerJson.append('Authorization', 'Bearer ' + adminToken);
    const requestOptions = {
      method: 'POST',
      headers: headerJson,
      body: JSON.stringify(demande)
    };
    return fetch(
        environment.api.referentiel_tiers + '/demandes/accepter/' + id,
        requestOptions
    );
  }
  annulerDemande(demande) {
    const adminToken = environment.token;
    const headerJson = new Headers();
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('WWW-Authenticate', 'Basic');
    headerJson.append('Authorization', 'Bearer ' + adminToken);
    const requestOptions = {
      method: 'DELETE',
      headers: headerJson,
      body: JSON.stringify(demande)
    };
    return fetch(
        environment.api.referentiel_tiers + '/demandes/',
        requestOptions
    );
  }
  migrerDossierDemande(params) {
    const adminToken = environment.token;
    const headerJson = new Headers();
    headerJson.append('Content-Type', 'application/json');
    headerJson.append('WWW-Authenticate', 'Basic');
    headerJson.append('Authorization', 'Bearer ' + adminToken);
    const requestOptions = {
      method: 'POST',
      headers: headerJson,
      body: JSON.stringify(params)
    };
    return fetch(
        environment.api.referentiel_dossier + '/dossier/migrateDossierEntreprise/',
        requestOptions
    );
  }
  getDemandeByAccountId(accountId) {
    return this.httpClient.get(environment.api.referentiel_tiers + '/demandes/tiers/' + accountId);
  }

  getDemandeByEntrepriseId(entrepriseId) {
    return this.httpClient.get(environment.api.referentiel_tiers + '/demandes/entreprise/' + entrepriseId);
  }
}
