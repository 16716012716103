<!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Renvoyer email de confirmation </h4>
  </div> -->
<div class="modal-body">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <p class="text-warning text-center">{{errorMessage}}</p>
            </div>
            <form [formGroup]="formReset" (ngSubmit)="submit()" class="col-md-12">
                <h3 class="text-center text-gras" style="margin-bottom: 1%;">Réinitialiser mon compte</h3>
                <p class="text-center">Vous ne parvenez-vous pas à vous connecter ?</p>
                <p style="font-size: 1.3em;">Pour récupérer votre compte vous devez reinitialiser votre mot de passe. Veuillez saisir votre adresse mail, un lien de réinitialisation vous sera envoyé.</p>

                <div class="col-md-12 form-group mb-3">
                    <label for="comment">Email <b>*</b></label>
                    <input
                            type="email"
                            class="form-control"
                            required=""
                            formControlName="email"
                    />
                </div>
                <div class="col-md-12 text-center">
                    <div class="loader-position" *ngIf="loading">
                        <div class="spinner spinner-primary mr-3"></div>
                    </div>
                </div>
                <div class="d-flex div-idea">
                    <img class="img-idea" src="assets/images/icons/warning.png">
                    <p>La reception du mail peut prendre un certain temps compte tenu du nombre de demandes en cours. Veuillez svp attendre d'avoir reçu le mail de réinitialisation avant de refaire cette procédure, sinon vous risquez de recevoir un lien de réinitialisation erroné.</p>
                </div>
                <div class="col-md-12 text-center">
                    <button
                            class="btn btn-primary m-1 btn-rounded"
                            type="submit"
                            [disabled]="!formReset.valid"
                    >
                        Valider
                    </button>
                    <button class="btn btn-light m-1 btn-rounded" (click)="cancel()">
                        Annuler
                    </button>
                </div>
            </form>
        </div>

    </div>
</div>
