<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Modification photo de profil
  </h4>
</div>
<div class="modal-body">
  <div class="card mb-4">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="col-md-12 form-group mb-3">
            <label for="comment">Télécharger une image</label>
            <input class="form-control" type="file" (change)="fileChangeEvent($event)" />
          </div>
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="4 / 3"
            format="png"
            (imageCropped)="imageCropped($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()">
          </image-cropper>
        </div>
        <div class="col-md-6 col-lg-6 text-center" style="background: #f1f1f1;">
          <label for="comment">Aperçu</label>
          <img [src]="croppedImage" />
        </div>
      </div>
      <div class="col-md-12 text-center">
        <div class="loader-position" *ngIf="loading">
          <div class="spinner spinner-primary mr-3"></div>
        </div>
      </div>
      <div class="col-md-12 text-center">
        <button
        (click)="savePicture()"
          class="btn btn-primary m-1"
          type="submit"
          [disabled]="!fileLoaded">
          Valider
        </button>
        <button class="btn btn-light m-1" (click)="cancel()">
          Annuler
        </button>
      </div>
    </div>
  </div>
</div>
