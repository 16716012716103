import { Injectable } from '@angular/core';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: any;
  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };
  constructor(
    private store: LocalStoreService,
    private router: Router,
    private httpClient: HttpClient,
    private localStoreService: LocalStoreService,
    private toastr: ToastrService) {
    this.token = this.localStoreService.getItem('citizen_access_token');

  }

  signin(user) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(
      environment.api.account_management + '/tokens',
      user,
      { headers, responseType: 'text' }
    );
  }

  signout() {
    this.store.clearAll();
    this.store.setItem('loginstatus', false);
    this.store.setItem('userInfos', null);
    this.toastr.success("Vous êtes déconnecté!", "", {
      timeOut: 3000,
    });
    this.router.navigateByUrl('/accueil');
  }

  handleError(statusCode) {
    switch (statusCode) {
      case 401:
        this.localStoreService.clearAll();
        this.router.navigateByUrl('/sessions/connexion');
    }
  }

  notifyUserAction() {
    this._userActionOccured.next();
  }

  editPassword(email, oldPassword, newPassword) {
    let headerJson = new Headers();
    headerJson.append("Content-Type", "application/json");
    headerJson.append('Authorization', 'Bearer ' + this.token);
    let data = { passwordOld: oldPassword, passwordNew: newPassword };
    var requestOptions = {
      method: 'PUT',
      headers: headerJson,
      body: JSON.stringify(data)
    };
    return fetch(
      environment.api.account_management + '/users/' + email + '/edit-password',
      requestOptions
    );
  }
  editPasswordAdmin(email, newPassword) {
    let headerJson = new Headers();
    headerJson.append("Content-Type", "application/json");
    headerJson.append('Authorization', 'Bearer ' + this.token);
    let data = {passwordOld: 'oldPassword', passwordNew: newPassword };
    var requestOptions = {
      method: 'PUT',
      headers: headerJson,
      body: JSON.stringify(data)
    };
    return fetch(
      environment.api.account_management + '/users/' + email + '/admin-edit-password',
      requestOptions
    );
  }

  getNotification(email) {
    return this.httpClient.get(
      environment.api.referentiel_notification + '/mails/' + email + '?limit=100'
    );
  }

  sendNotificationAfterSign(codeFichier, addresses, dossier) {
    let data = {
      subject: "subject",
      addresses: addresses,
      codeFichier: codeFichier,
      templateid: 'signature_acte',
      templatedata: dossier
    }
    return this.httpClient.post(
      environment.api.referentiel_notification + '/mails/', data
    );

  }

  sendNotificationResetAccount(addresses, templatedata) {
    const data = {
      subject: '[ENA] Réinitialiser mon compte',
      addresses: addresses,
      codeFichier: '',
      templateid: 'reset_account_ena',
      templatedata: templatedata
    };
    return this.httpClient.post(
      environment.api.referentiel_notification + '/mail/', data
    );

  }

  getInfosSignature(idAccount) {
    return this.httpClient.get(
      environment.api.account_management + '/signataires/' + idAccount
    );
  }

  signUp(user) {
    let data = user;
    return this.httpClient.post(
      environment.api.account_management + '/users', data
    );
  }
  signUpRandomPassword(user) {
    let data = user;
    return this.httpClient.post(
      environment.api.account_management + '/users/admin', data
    );
  }

  resendConfirmation(email) {
    let headerJson = new Headers();
    headerJson.append("Accept", "application/json");
    var requestOptions = {
      method: 'PUT',
      headers: headerJson
    };
    return fetch(
      environment.api.account_management + '/users/' + email + '/sendConfirmation',
      requestOptions
    );
  }


  recoverPassword(email, token, password) {
    let headerJson = new Headers();
    let data = { password: password, token: token }
    headerJson.append('Content-Type', 'application/json');
    var requestOptions = {
      method: 'PUT',
      headers: headerJson,
      body: JSON.stringify(data)
    };
    return fetch(
      environment.api.account_management + '/users/' + email + '/reset-password',
      requestOptions
    );
  }

  resetPassword(email) {
    let headerJson = new Headers();
    headerJson.append("Accept", "application/json");
    var requestOptions = {
      method: 'PUT',
      headers: headerJson
    };
    return fetch(
      environment.api.account_management + '/users/' + email + '/reset-password-begin',
      requestOptions
    );
  }

  activeUser(email,token) {
    var requestUrl = environment.api.account_management + '/users/'+email+'/active';
    let headerJson = new Headers();
    headerJson.append("Accept", "application/json");
    headerJson.append("Content-Type", "text/plain");
    var requestOptions = {
      method: 'PUT',
      headers: headerJson,
      body: JSON.stringify(token)
    };
    return fetch(
      requestUrl,
      requestOptions
    );
  }
}
