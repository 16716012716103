import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStoreService } from './local-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

  constructor(
    private router: Router,
    private localStoreService : LocalStoreService
  ) { }

  canActivate() {
    let accessToken = this.localStoreService.getItem('citizen_access_token');
    if (accessToken) {
      return true;
    } else {
      this.router.navigateByUrl('/sessions/connexion');
    }
  }
}
