<div class="main-header" *ngIf="displayBloc">
    <div class="logo">
        <h1>CONCOURS ENA</h1>
     </div>
    
        <div class="menu-toggle" (click)="toggelSidebar()">
            <div></div>
            <div></div>
            <div></div>
        </div>
    
        <div style="margin: auto"></div>
    
        <div class="header-part-right">
            <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
           <!--  <div ngbDropdown [placement]="'bottom-right'">
                <div class="badge-top-container">
                    <span class="badge badge-primary">{{notifications?.length}}</span>
                    <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
                </div>
                <div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>
                    <div class="dropdown-item d-flex" *ngFor="let item of notifications" [routerLink]="[item.link]">
                        <div class="notification-icon">
                            <i class="{{item.icon}} text-{{item.status}} mr-1"></i>
                        </div>
                        <div class="notification-details flex-grow-1">
                            <p class="m-0 d-flex align-items-center">
                                <span>{{item.title}}</span>
                                <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ml-1 mr-1">{{item.badge}}</span>
                                <span class="flex-grow-1"></span>
                                <span class="text-small text-muted ml-auto">{{item.time | relativeTime}}</span>
                            </p>
                            <p class="text-small text-muted m-0">{{item.text | excerpt:30}}</p>
                        </div>
                    </div>
                </div>
            </div> -->
            <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end cursor">
                <img  [src]="avatar" id="userDropdown" ngbDropdownToggle alt="" *ngIf="avatar">
                <img src="./assets/images/faces/1.jpg" id="userDropdown" ngbDropdownToggle alt="" *ngIf="avatar == null">
                <div ngbDropdownMenu aria-labelledby="userDropdown">
                    <div class="dropdown-header" *ngIf="user.userType !== 'entreprise'">
                        <i class="i-Lock-User mr-1"></i> {{user.firstName + ' '+ user.lastName}}
                        <hr class="hr-margin">
                    </div>
                    <div class="dropdown-header" *ngIf="user.userType === 'entreprise'">
                        <i class="i-Lock-User mr-1"></i> {{user.userName}}
                        <hr class="hr-margin">
                    </div>
                    <button class="dropdown-item" (click)="goToProfil()">Mon profil</button>
                    <button class="dropdown-item" (click)="goToUpdatePassword()">Modifier mot de passe</button>
                    <button class="dropdown-item" routerLink="/accueil/faq">Aide</button>
                    <button class="dropdown-item" (click)="signout()">Se déconnecter</button>
                </div>
            </div>
        </div>
    </div>
