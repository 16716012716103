<div style="padding: 30px">
  <form [formGroup]="passwordForm" (submit)="submit()">
    <div class="form-group mb-3">
      <label for="password">Mot de passe actuel</label>
    </div>
    <div class="input-group form-group">
      <input
        formControlName="oldPassword"
        [type]="show ? 'text' : 'password'"
        class="form-control"
      />
      <div class="input-group-append">
        <span
          (click)="showPassword()"
          class="input-group-text"
          id="basic-addon2"
        >
          <i *ngIf="!show" class="i-Lock-2"></i>
          <i *ngIf="show" class="i-Unlock-2"></i>
        </span>
      </div>
    </div>
    <div class="form-group mb-3">
      <label for="password">Nouveau mot de passe</label>
    </div>
    <div class="input-group form-group">
      <input (focus)="onFocus()" (blur)="onBlur()"
        formControlName="newPassword"
        [type]="showNew ? 'text' : 'password'"
        class="form-control"
      />
      <div class="input-group-append">
        <span
          (click)="showPasswordNew()"
          class="input-group-text"
          id="basic-addon2"
        >
          <i *ngIf="!showNew" class="i-Lock-2"></i>
          <i *ngIf="showNew" class="i-Unlock-2"></i
        ></span>
      </div>
    </div>
    <div class="form-group" *ngIf="isFocusPassword">
      <label *ngIf="passwordForm.controls['newPassword'].hasError('required') ||
      passwordForm.controls['newPassword'].hasError('hasNumber')"
        class="col"
        [ngClass]="
        passwordForm.controls['newPassword'].hasError('required') ||
        passwordForm.controls['newPassword'].hasError('hasNumber')
            ? 'text-danger'
            : 'text-success'
        "
      >
        Le mot de passe doit contenir au moins un chiffre
      </label>
      <label *ngIf="passwordForm.controls['newPassword'].hasError('required') ||
      passwordForm.controls['newPassword'].hasError('hasCapitalCase')"
        class="col"
        [ngClass]="
          passwordForm.controls['newPassword'].hasError('required') ||
          passwordForm.controls['newPassword'].hasError('hasCapitalCase')
            ? 'text-danger'
            : 'text-success'
        "
      >
        Le mot de passe doit contenir au moins une lettre majuscule
      </label>
      <label *ngIf="passwordForm.controls['newPassword'].hasError('required') ||
      passwordForm.controls['newPassword'].hasError('hasSmallCase')"
        class="col"
        [ngClass]="
          passwordForm.controls['newPassword'].hasError('required') ||
          passwordForm.controls['newPassword'].hasError('hasSmallCase')
            ? 'text-danger'
            : 'text-success'
        "
      >
        Le mot de passe doit contenir au moins une lettre minuscule
      </label>
    </div>
    <div class="form-group mb-3">
      <label
        for="confirmPassword"
       >Confirmer le mot de passe</label
      >
    </div>
    <div class="input-group form-group">
      <input (focus)="onFocus()" (blur)="onBlur()"
        formControlName="newPasswordConfirm"
        [type]="showConfirm ? 'text' : 'password'"
        class="form-control"
       />
      <div class="input-group-append">
        <span
          (click)="showPasswordConfirm()"
          class="input-group-text"
          id="basic-addon2"
        >
          <i *ngIf="!showConfirm" class="i-Lock-2"></i>
          <i *ngIf="showConfirm" class="i-Unlock-2"></i
        ></span>
      </div>
    </div>
    <div class="form-group">
      <label
        class="text-danger"
        *ngIf="
          passwordForm.controls['newPasswordConfirm'].hasError(
            'NoPassswordMatch'
          )
        "
      >
        Les mots de passe ne sont pas identiques
      </label>
    </div>
    <div class="col-md-12 text-center">
      <div class="loader-position" *ngIf="loading">
        <div class="spinner spinner-primary mr-3"></div>
      </div>
    </div>
    <div class="form-group">
      <button
        class="btn btn-primary btn-block m-1"
        type="submit"
        [disabled]="!passwordForm.valid"
      >
        Valider
      </button>
    </div>
  </form>
</div>
