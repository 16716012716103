import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { Subject, Subscription, timer } from 'rxjs';
import { ReferentielDossierService } from 'src/app/shared/services/referentiel-dossier/referentiel-dossier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { PasswordComponent } from '../../../password/password.component';

@Component({
  selector: 'app-header-sidebar-compact',
  templateUrl: './header-sidebar-compact.component.html',
  styleUrls: ['./header-sidebar-compact.component.scss']
})
export class HeaderSidebarCompactComponent implements OnInit {
  notifications: any[];
  pageOfNotifications: Array<any>;
  user: any;
  agent: any;
  loading: boolean;
  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime = 1;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  avatar: any = null;
  displayBloc = true;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    private localStoreService: LocalStoreService,
    private referentielDossierService: ReferentielDossierService,
    private router: Router,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.resetTimer();
    this.auth.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });
      const userInfos = this.localStoreService.getItem('userInfos');
      if (userInfos.avatar) {
        this.avatar = userInfos.avatar;
      }
      this.user = userInfos.user;
      /*this.loading = true;
      this.auth.getNotification(this.user.email).subscribe(
        (response: any) => {
          this.loading = false;
          this.notifications = response.data
        },
        (error) => {
          this.auth.handleError(error.status)
          console.log(error);
          this.loading = false;
        }
      );*/

      this.checkDisplayBloc();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onChangePage(pageOfNotifications: Array<any>) {
    this.pageOfNotifications = pageOfNotifications;
  }


  resetTimer(endTime: number = this.endTime) {
    const interval = 30000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
      this.render((duration - +value) * interval),
      err => { },
      () => {
        this.auth.signout();
      }
    );
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }


  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
  }

  signout() {
    this.auth.signout();
  }

  goToProfil() {
    this.router.navigate(['/account/profil']);
  }

  goToUpdatePassword() {
    let options = {
      size: 'md',
      windowClass: 'password-modal',
    };
    this.modalService.open(PasswordComponent, options);
  }

  checkDisplayBloc() {
    this.activateRoute.queryParams.subscribe((params) => {
      if (params && params.displayBloc === 'false') {
          this.displayBloc = false;
      }
    });
  }


/*   showMessage(item) {
    let options = { size: "lg" };
    let modalMessage = this.modalService.open(MessageNotificationComponent, options);
    modalMessage.componentInstance.item = item;
  } */
}
