import { Component, OnInit } from "@angular/core";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UtilsService } from "../../services/utils.service";
import { ReferentielPieceService } from '../../services/referentiel-piece/referentiel-piece.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStoreService } from '../../services/local-store.service';
import { ReferentielTierService } from '../../services/referentier-tier/referentiel-tier.service';
import { ToastrService } from 'ngx-toastr';
import { analyzeNgModules } from "@angular/compiler";
import {NgxImageCompressService} from 'ngx-image-compress';

@Component({
    selector: "app-upload-profile-picture",
    templateUrl: "./upload-profile-picture.component.html",
    styleUrls: ["./upload-profile-picture.component.scss"],
})
export class UploadProfilePictureComponent implements OnInit {
    imageChangedEvent: any = '';
    imgResultAfterCompress: any = '';
    croppedImage: any = '';
    file: any;
    loading: boolean;
    userInfos: any;
    fileLoaded = false;

    constructor(private utilService: UtilsService,
        private referentielPieceService: ReferentielPieceService,
        private modalService: NgbModal,
        private localStoreService: LocalStoreService,
        private referentielTierService: ReferentielTierService,
        private toastr: ToastrService,
        private imageCompress: NgxImageCompressService
        ) { }

    ngOnInit(): void {
        this.userInfos = this.localStoreService.getItem('userInfos');
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.imageCompress.compressFile(this.croppedImage, null, 120, 120).then(
            result => {
              this.imgResultAfterCompress = result;
              this.fileLoaded = true;
            }
          );
        
    }

    imageLoaded(image: HTMLImageElement) {
        // show cropper
    }
    cropperReady() {

    }
    loadImageFailed() {
        // show message
    }

    savePicture() {
        this.loading = true;
        this.referentielTierService
            .updateAvatar(this.userInfos.user.id, this.imgResultAfterCompress)
            .then((response) => {
                this.referentielTierService.getEcitizenByAccountId(this.userInfos.user.id).subscribe(
                    (responsedata: any) => {
                        this.userInfos.avatar = responsedata.avatar;
                        this.localStoreService.setItem('userInfos', this.userInfos);
                        this.loading = false;
                        window.location.reload();
                    },
                    (error) => {
                        console.log(error);
                    }
                )
            }) 
            .catch((error) => {
                this.loading = false;
                this.modalService.dismissAll();
                console.log(error);
            });
    }

    cancel() {
        this.modalService.dismissAll();
    }

    updateTiersProfile(item) {
        this.referentielTierService.getEcitizenByAccountId(this.userInfos.user.id).subscribe(
            (response: any) => {
                let citizen = response;
                citizen.codeProfileImg = this.croppedImage;                
                this.referentielTierService.updateCitiZen(citizen.id, citizen)
                .then((response) => response.json())
                .then((response) => {
                    this.loading = false;
                    this.toastr.success("Mise à jour réussi !", "", {
                        timeOut: 6000,
                      });
                      this.modalService.dismissAll();
                    },
                    (error) => {
                        console.log(error);
                    })
            },
            (error) => {
                console.log(error);
            }
        )
    }

    updateEntrepriseProfile(item) {

    }
}
