import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreService } from '../shared/services/local-store.service';
import {environment} from '../../environments/environment';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private localStoreService: LocalStoreService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenAuthorization = this.localStoreService.getItem("citizen_access_token");
    if (tokenAuthorization) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer '+tokenAuthorization,
        },
      });
    } else {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + environment.token,
        },
      });
    }
    return next.handle(req);
  }
}
