import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup;
  loading: boolean;
  show: boolean;
  showNew: boolean;
  showConfirm: boolean;
  isFocusPassword: boolean;
  loadingText: string;
  user: any;
  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private authService: AuthService,
    private localStoreService: LocalStoreService,
    private toastr: ToastrService,
    private router: Router,
    public modalService: NgbModal){
    this.passwordForm = this.createSignupForm();
  }

  ngOnInit() {
    this.user = this.localStoreService.getItem('userInfos');
  }

  showPassword() {
    this.show = !this.show;
  }

  showPasswordNew() {
    this.showNew = !this.showNew;
  }

  showPasswordConfirm() {
    this.showConfirm = !this.showConfirm;
  }

  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        oldPassword: [null, Validators.required],
        newPassword: [
          null,
          Validators.compose([
            Validators.required,
            this.utilsService.patternValidator(/\d/, {
              hasNumber: true,
            }),
            this.utilsService.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            this.utilsService.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            this.utilsService.patternValidator(
              /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
          ]),
        ],
        newPasswordConfirm: [null, Validators.compose([Validators.required])],
      },
      {
        validator: this.utilsService.passwordMatchValidator,
      }
    );
  }

  submit() {
    let oldPassword = this.passwordForm.get('oldPassword').value;
    let newPassword = this.passwordForm.get('newPassword').value;
    this.loading = true;
    this.authService
      .editPassword(this.user.user.email, oldPassword, newPassword)
      .then((response) => response.json())
      .then(
        (response: any) => {
          this.loading = false;
          this.toastr.success(response.message, '', {
            timeOut: 6000,
          });
          this.modalService.dismissAll();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onFocus() {    
    this.isFocusPassword = true;
  }

  onBlur() {
    this.isFocusPassword = false;
  }
}
