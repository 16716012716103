<div class="app-admin-wrap">
    <div class="">
        <nav id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom headroom--top headroom--pinned">
    <div class="container">
      <a class="navbar-brand mr-lg-5" [routerLink]="['/accueil']">
        <img src="./assets/images/ena.jpg">
      </a>
      <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbar_global" [ngbCollapse]="isCollapsed">
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a [routerLink]="['/accueil']">
                <img src="./assets/images/ena.jpg">
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!--<ul class="navbar-nav navbar-nav-hover align-items-lg-center">
          <li class="nav-item dropdown">
            <a class="nav-link no-caret" href="https://senegalservices.sn/service-en-ligne" target="_blank" data-toggle="dropdown" role="button">
              <i class="ni ni-ui-04 d-lg-none"></i>
              <span class="nav-link-inner&#45;&#45;text">Téléprocédures</span>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link no-caret" target="_blank" href="https://senegalservices.sn/actualites" data-toggle="dropdown" role="button">
              <i class="ni ni-collection d-lg-none"></i>
              <span class="nav-link-inner&#45;&#45;text">Actualités</span>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link no-caret" routerLink="/accueil/faq" data-toggle="dropdown" role="button">
              <i class="ni ni-collection d-lg-none"></i>
              <span class="nav-link-inner&#45;&#45;text" >Aide</span>
            </a>
          </li>
          <div class="nav-item dropdown">
            <a class="nav-link no-caret dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Lien utiles
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              &lt;!&ndash; <a class="dropdown-item" target="_blank" href="https://ess.sec.gouv.sn/e-citoyen/spip.php?rubrique8">E-Pétition</a> &ndash;&gt;
              <a class="dropdown-item" target="_blank" href="http://www.servicepublic.gouv.sn/">Service public</a>
              <a class="dropdown-item" target="_blank" href="http://infos.teledac.gouv.sn/">Infos Teledac</a>
            </div>
          </div>
        </ul>-->
        <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
          <li class="nav-item">
            <a routerLink="/sessions/connexion" class="">
              <button class="btn btn-outline-primary mt-3 mb-3 btn-sm">Se connecter</button>
            </a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a class="">
              <button [routerLink]="['/sessions/inscription']" class="btn btn-outline-secondary mt-3 mb-3 btn-sm">Créer un compte</button>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>


  <main>
    <div class="position-relative">
      <section class="section section-lg section-hero">
        <div class="container shape-container d-flex align-items-center mt-20">
        
          <div class="col px-0">
            <div class="row align-items-center">
                    <div class="container mt-20" style="width: 50%;margin: auto;">
                        <h4>Récupérer votre compte</h4>

                        <div style="padding: 30px">
                            <p class="text-warning">{{errorMessage}}</p>
                            <p>Pour récupérer votre compte, saisissez votre nouveau mot de passe. </p>
                            <form [formGroup]="passwordForm" (submit)="submit()">
                              <div class="form-group mb-3">
                                <label>Nouveau mot de passe</label>
                              </div>
                              <div class="input-group form-group">
                                <input (focus)="onFocus()" (blur)="onBlur()"
                                  formControlName="newPassword"
                                  [type]="showNew ? 'text' : 'password'"
                                  class="form-control"
                                />
                                <div class="input-group-append">
                                  <span
                                    (click)="showPasswordNew()"
                                    class="input-group-text"
                                    id="basic-addon2"
                                  >
                                    <i *ngIf="!showNew" class="i-Lock-2"></i>
                                    <i *ngIf="showNew" class="i-Unlock-2"></i
                                  ></span>
                                </div>
                              </div>
                              <div class="form-group" *ngIf="isFocusPassword">
                                <label *ngIf="passwordForm.controls['newPassword'].hasError('required') ||
                                passwordForm.controls['newPassword'].hasError('hasNumber')"
                                  class="col"
                                  [ngClass]="
                                  passwordForm.controls['newPassword'].hasError('required') ||
                                  passwordForm.controls['newPassword'].hasError('hasNumber')
                                      ? 'text-danger'
                                      : 'text-success'
                                  "
                                >
                                  Le mot de passe doit contenir au moins un chiffre
                                </label>
                                <label *ngIf="passwordForm.controls['newPassword'].hasError('required') ||
                                passwordForm.controls['newPassword'].hasError('hasCapitalCase')"
                                  class="col"
                                  [ngClass]="
                                    passwordForm.controls['newPassword'].hasError('required') ||
                                    passwordForm.controls['newPassword'].hasError('hasCapitalCase')
                                      ? 'text-danger'
                                      : 'text-success'
                                  "
                                >
                                  Le mot de passe doit contenir au moins une lettre majuscule
                                </label>
                                <label *ngIf="passwordForm.controls['newPassword'].hasError('required') ||
                                passwordForm.controls['newPassword'].hasError('hasSmallCase')"
                                  class="col"
                                  [ngClass]="
                                    passwordForm.controls['newPassword'].hasError('required') ||
                                    passwordForm.controls['newPassword'].hasError('hasSmallCase')
                                      ? 'text-danger'
                                      : 'text-success'
                                  "
                                >
                                  Le mot de passe doit contenir au moins une lettre minuscule
                                </label>
                              </div>
                              <div class="form-group mb-3">
                                <label>Confirmer le mot de passe</label>
                              </div>
                              <div class="input-group form-group">
                                <input (focus)="onFocus()" (blur)="onBlur()"
                                  formControlName="newPasswordConfirm"
                                  [type]="showConfirm ? 'text' : 'password'"
                                  class="form-control"
                                 />
                                <div class="input-group-append">
                                  <span
                                    (click)="showPasswordConfirm()"
                                    class="input-group-text"
                                    id="basic-addon3"
                                  >
                                    <i *ngIf="!showConfirm" class="i-Lock-2"></i>
                                    <i *ngIf="showConfirm" class="i-Unlock-2"></i
                                  ></span>
                                </div>
                              </div>
                              <div class="form-group">
                                <label
                                  class="text-danger"
                                  *ngIf="
                                    passwordForm.controls['newPasswordConfirm'].hasError(
                                      'NoPassswordMatch'
                                    )
                                  "
                                >
                                  Les mots de passe ne sont pas identiques
                                </label>
                              </div>
                              <div class="col-md-12 text-center">
                                <div class="loader-position" *ngIf="loading">
                                  <div class="spinner spinner-primary mr-3"></div>
                                </div>
                              </div>
                              <div class="form-group">
                                <button
                                  class="btn btn-primary m-1"
                                  type="submit"
                                  [disabled]="!passwordForm.valid"
                                >
                                  Valider
                                </button>
                              </div>
                            </form>
                          </div>
                          
                      
                    </div>
            </div>
          </div>
        </div>
        <!-- SVG separator -->
        <div class="separator separator-bottom separator-skew zindex-100">
          <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </section>
    </div>
  </main>
  



</div>
</div>
