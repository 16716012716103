import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location, PopStateEvent} from '@angular/common';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {LocalStoreService} from '../shared/services/local-store.service';
import {UtilsService} from '../shared/services/utils.service';
import {AuthService} from '../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reset-account',
  templateUrl: './reset-account.component.html',
  styleUrls: ['./reset-account.component.scss']
})
export class ResetAccountComponent implements OnInit {
  public isCollapsed = true;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  passwordForm: FormGroup;
  loading: boolean;
  isFocusPassword: boolean;
  loadingText: string;
  token: any;
  email: any;
  show: boolean;
  showNew: boolean;
  showConfirm: boolean;
  errorMessage: any;

  constructor(public location: Location, private router: Router,
              public localStoreService: LocalStoreService,
              private fb: FormBuilder,
              private utilsService: UtilsService,
              private activateRoute: ActivatedRoute,
              private authService: AuthService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.errorMessage = '';
    this.activateRoute.queryParams.subscribe((params) => {
      this.email = params.email;
      this.token = params.token;
    });
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.passwordForm = this.createSignupForm();
  }

  isHome() {
    const titlee = this.location.prepareExternalUrl(this.location.path());
    return titlee === '#/home';
  }
  isDocumentation() {
    const titlee = this.location.prepareExternalUrl(this.location.path());
    return titlee === '#/documentation';
  }
  submit() {
    this.loading = true;
    this.authService.editPasswordAdmin(this.email, this.passwordForm.get('newPassword').value)
        .then(
            (response: any) => {
              this.loading = false;
              if (response.status === 400) {
                this.errorMessage = 'Une erreur est survenue lors de l\'activation  de l\'utilisateur : Impossible de reinitialiser le mot de passe de cet utilisateur.'
              } else {
                this.toastr.success('Mot de passe réinitialisé !', '', {
                  timeOut: 3000,
                });
                this.router.navigate(['/sessions/connexion']);
              }
            },
            (error) => {
              this.loading = false;
              console.log(error);
            }
        );
  }

  createSignupForm(): FormGroup {
    return this.fb.group(
        {
          newPassword: [
            null,
            Validators.compose([
              Validators.required,
              this.utilsService.patternValidator(/\d/, {
                hasNumber: true,
              }),
              this.utilsService.patternValidator(/[A-Z]/, {
                hasCapitalCase: true,
              }),
              this.utilsService.patternValidator(/[a-z]/, {
                hasSmallCase: true,
              }),
              this.utilsService.patternValidator(
                  /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/,
                  {
                    hasSpecialCharacters: true,
                  }
              ),
              Validators.minLength(8),
            ]),
          ],
          newPasswordConfirm: [null, Validators.compose([Validators.required])],
        },
        {
          validator: this.utilsService.passwordMatchValidator,
        }
    );
  }

  onFocus() {
    this.isFocusPassword = true;
  }

  onBlur() {
    this.isFocusPassword = false;
  }


  showPassword() {
    this.show = !this.show;
  }

  showPasswordNew() {
    this.showNew = !this.showNew;
  }

  showPasswordConfirm() {
    this.showConfirm = !this.showConfirm;
  }
}
