// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  api:{
    account_management: "/account-management-ena",
    referentiel_tiers: "/referentiel-tiers",
    referentiel_dossier: "/referentiel-dossier",
    referentiel_engine: "/engine",
    referentiel_form: "/referentiel-formulaire",
    referentiel_piece: "/referentiel-piece",
    referentiel_structure: "/referentiel-structure",
    referentiel_statistique: "/referentiel-refonte-statistique",
    referentiel_rendezvous: "/referentiel-rendezvous",
    referentiel_notification: "/notification",
    referentiel_signature: "/referentiel-signature",
    referentiel_concours: "/referentiel-concours",
    data_collect: "data-collect"
  },
  token: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ7XCJpZFwiOjE3LFwidXNlck5hbWVcIjpcInN0cmluZ1wiLFwiZmlyc3ROYW1lXCI6XCJzdHJpbmdcIixcImxhc3ROYW1lXCI6XCJzdHJpbmdcIixcImVtYWlsXCI6XCJtb3VoYW1hZG91LnNvd0BuZXRpY29hLmZyXCIsXCJ1c2VyVHlwZVwiOlwiY2l0b3llblwiLFwicm9sZXNcIjpbXCJBZG1pblwiLFwiU2ltcGxlXCJdfSIsImlzcyI6ImFjY291bnQtbWFuYWdlbWVudCJ9.M6DKHDn-l1_a9QmkXuKwyAhghxW6QaB8vE3kHRQ0eds'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
