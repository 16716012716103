<div [ngClass]="{open: navService.sidebarState.sidenavOpen}" class="sidebar-left rtl-ps-none" perfectScrollbar style="background-color:#ff8000 !important">
     <ul class="navigation-left">
          <li [ngClass]="{active: item.active}"
            (click)="onClickChangeActiveFlag(item)"
            class="nav-item lvl1" 
            *ngFor="let item of nav"
          >
            <div class="nav-item-hold" routerLink="{{item.state}}" (click)="closeChildNav()" *ngIf="!item.disabled && item.type === 'link'">
              <i [class]="'nav-icon '+ item.icon "></i>
              <span class="nav-text">{{item?.name}}</span>
            </div>
            <div class="nav-item-hold" (mouseenter)="selectItem(item)" *ngIf="!item.disabled && item.type === 'dropDown'">
              <i [class]="'nav-icon '+ item.icon "></i>
              <span class="nav-text">{{item?.name}}</span>
            </div>
            <div class="nav-item-hold" *ngIf="!item.disabled && item.type === 'extLink'">
              <a [href]="item.state" target="_blank">
                  <i [class]="'nav-icon '+ item.icon "></i>
                  <span class="nav-text">{{item?.name}}</span>
              </a>
            </div>
    
            <div class="triangle" *ngIf="!item.disabled"></div>
          </li>
        </ul>
    </div>
    
    <div [ngClass]="{open: navService.sidebarState.childnavOpen}" class="sidebar-left-secondary rtl-ps-none" perfectScrollbar >
        <i class="sidebar-close i-Close" (click)="toggelSidebar()"></i>
        <header>
          <div class="logo cursor">
            <a routerLink="/accueil">
              <img src="./assets/images/ena.jpg" alt="">
            </a>
        </div>
        <div style="text-align: center; margin-bottom: 30px;">
          <div class="cursor" (click)="uploadPicture()">
            <p *ngIf="avatar == null" class=""><img class="img-profil" src="./assets/images/faces/1.jpg"/></p>
            <p class="user-profile"><img class="img-profil"  [src]="avatar" *ngIf="avatar"/></p>
          </div>
            <p class="p-type" *ngIf="user.user.type === null || user.user.type === 'citoyen'">candidat</p>
            <p class="p-type" *ngIf="user.user.type === 'entreprise'">entreprise</p>
            <p class="p-type" *ngIf="user.user.type === 'representant_entreprise'">affilié</p>
            <p class="p-fonction" *ngIf="user.entreprise !== undefined">{{user?.entreprise?.nomEntreprise}}</p>
            <p class="p-nom" *ngIf="user.user.type === 'entreprise'">{{user?.user.userName}}</p>
            <p class="p-nom" *ngIf="user.user.type !== 'entreprise'">{{user?.user.firstName + ' ' + user?.user.lastName}}</p>
            <p class="p-fonction" *ngIf="user.fonction !== undefined && user.user.type !== 'entreprise'">{{user?.fonction}}</p>
        </div>
        </header>
        <ng-container *ngTemplateOutlet="menuTemplate;context:{parentItem: selectedItem}"></ng-container>
    </div>
    
    <ng-template #menuTemplate let-parentItem="parentItem">
      <ul class="childNav" appDropdown>
        <li *ngFor="let item of parentItem?.sub" appDropdownLink class="nav-item">
          <a  routerLink="{{item.state}}" *ngIf="item.type === 'link' && item.permission" class="" [ngClass]="{open: item.active}" routerLinkActive="open">
            <i *ngIf="item.icon" [class]="'nav-icon '+ item.icon "></i>
            <span *ngIf="item.name != 'Mes rendez-vous'" class="item-name lvl1">{{item?.name}}</span>
            <span *ngIf="item.name == 'Mes rendez-vous'" class="item-name lvl1">{{item?.name}}&nbsp; <span class="badge badge-pill badge-primary">
              {{nombreDeRDV}}
            </span></span>
          </a>
          <a target="_blank" href="{{item.state}}" *ngIf="item.type === 'href' && item.permission" class="" [ngClass]="{open: item.active}" routerLinkActive="open">
            <i *ngIf="item.icon" [class]="'nav-icon '+ item.icon "></i>
            <span class="item-name lvl1">{{item?.name}}</span>
          </a>
          <div *ngIf="item.type === 'dropDown' && item.permission">
            <a appDropdownToggle [ngClass]="{open: item.active}" routerLinkActive="open">
              <i *ngIf="item.icon" [class]="'nav-icon '+ item.icon "></i>
              <span class="item-name">{{item?.name}}</span>
              <i class="dd-arrow i-Arrow-Down"></i>
            </a>
              <ng-container  *ngTemplateOutlet="menuTemplate;context:{parentItem: item}"></ng-container>
          </div>
    
        </li>
      </ul>
    </ng-template>
    
    <div [ngClass]="{open: navService.sidebarState.childnavOpen}" class="sidebar-overlay" (click)="closeChildNav()"></div>
