import { Component, OnInit, HostListener } from '@angular/core';
import {
  NavigationService,
  IMenuItem,
  IChildItem
} from '../../../../services/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Utils } from '../../../../utils';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProfilePictureComponent } from '../../../upload-profile-picture/upload-profile-picture.component';
import { ReferentielPieceService } from 'src/app/shared/services/referentiel-piece/referentiel-piece.service';
import { ReferentielRendezVousService } from 'src/app/shared/services/referentiel-rendez-vous/referentiel-rendez-vous.service';

@Component({
  selector: 'app-sidebar-compact',
  templateUrl: './sidebar-compact.component.html',
  styleUrls: ['./sidebar-compact.component.scss']
})
export class SidebarCompactComponent implements OnInit {
  selectedItem: IMenuItem;
  nav: IMenuItem[];
  user: any;
  base64dataProfile: any;
  avatar: any = null;
  showImmatriculationMarin = false;
  tierInfos: any;
  nombreDeRDV: any;
  constructor(public router: Router, public navService: NavigationService,
    private localStoreService: LocalStoreService,
    private modalService: NgbModal,
    private referentielPiecesServices: ReferentielPieceService,
    private referentielRendezVousServices: ReferentielRendezVousService) { }

  ngOnInit() {
    const userInfos = this.localStoreService.getItem('userInfos');
    this.tierInfos = this.localStoreService.getItem('tierInfos');
    if (userInfos.avatar) {
      this.avatar = userInfos.avatar;
    }
    this.user = userInfos;
    // console.log(' this.user',  this.user);
    this.updateSidebar();
    // CLOSE SIDENAV ON ROUTE CHANGE
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(routeChange => {
        this.closeChildNav();
        if (Utils.isMobile()) {
          this.navService.sidebarState.sidenavOpen = false;
        }
      });

    this.navService.menuItems$.subscribe(items => {
      this.nav = items;
      this.setActiveFlag();
    });
    // this.getRVNumber();
  }

  selectItem(item) {
    this.navService.sidebarState.childnavOpen = true;
    this.selectedItem = item;
    this.setActiveMainItem(item);
  }
  closeChildNav() {
    this.navService.sidebarState.childnavOpen = false;
    this.setActiveFlag();
  }

  uploadPicture() {
    const options = { size: 'lg' };
    const modalImage = this.modalService.open(UploadProfilePictureComponent, options);
  }

  onClickChangeActiveFlag(item) {
    this.setActiveMainItem(item);
  }
  setActiveMainItem(item) {
    this.nav.forEach(item => {
      item.active = false;
    });
    item.active = true;
  }

  setActiveFlag() {
    if (window && window.location) {
      const activeRoute = window.location.hash || window.location.pathname;
      this.nav.forEach(item => {
        item.active = false;
        if (activeRoute.indexOf(item.state) !== -1) {
          this.selectedItem = item;
          item.active = true;
        }
        if (item.sub) {
          item.sub.forEach(subItem => {
            subItem.active = false;
            if (activeRoute.indexOf(subItem.state) !== -1) {
              this.selectedItem = item;
              item.active = true;
            }
            if (subItem.sub) {
              subItem.sub.forEach(subChildItem => {
                if (activeRoute.indexOf(subChildItem.state) !== -1) {
                  this.selectedItem = item;
                  item.active = true;
                  subItem.active = true;
                }
              });
            }
          });
        }
      });
    }
  }

  updateSidebar() {
    if (Utils.isMobile()) {
      this.navService.sidebarState.sidenavOpen = false;
      this.navService.sidebarState.childnavOpen = false;
    } else {
      this.navService.sidebarState.sidenavOpen = true;
    }
  }
  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSidebar();
  }

  setProfileImage(codeProfileImg) {
    if (!codeProfileImg) {
      this.router.navigate(['accueil']);
    } else {
      this.referentielPiecesServices.downloadPiece(codeProfileImg)
        .subscribe((response) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = () => {
            const blob = new Blob([reader.result], { type: 'image/png' });

            // this.base64dataProfile = reader.result;

          };
          // this.router.navigate(['accueil'])
        },
          (error) => {
            // this.router.navigate(['accueil'])
          });
    }
  }
  getRVNumber() {
    this.referentielRendezVousServices.countCitoyenRDV(this.user.user.id).subscribe(
      (response) => {
        this.nombreDeRDV = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
