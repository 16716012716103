import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-verification',
  templateUrl: './form-verification.component.html',
  styleUrls: ['./form-verification.component.scss']
})
export class FormVerificationComponent implements OnInit {
  @Input() template;
  @Input() dosData;
  @Input() isReadOnly;

  constructor() { }

  ngOnInit(): void {

  }

}
