<div class="app-footer" *ngIf="displayBloc">
	<div class="row justify-content-center" >
		<div class="col-md-11">
			<h6>ENA 2024 - par SENUM SA</h6>
		</div>
		
		<div class="col-md-1">
			<a class="btn btn-outline-primary btn-rounded btn-sm"
			   routerLink="/accueil/faq">Support</a>
		</div>
	</div>
</div>
